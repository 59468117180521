<template>
  <div>
    <vx-card class="mb-base button-no-border">
      <vs-button type="border" @click="activeContact" :class="(contactCard === true)  ? 'active' : ''">Contact</vs-button>
      <vs-button type="border" class="ml-8" :class="(tutorialCard === true)  ? 'active' : ''" @click="activeTutorial">Tutorials</vs-button>
    </vx-card>
    <!--contact card-->
    <vx-card class="mb-base" v-if="contactCard === true">
      <h2 class="mb-10 font-bold">Contact Personhood 360</h2>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="split-form-detail">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-1/2">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <div class="w-full mb-5">
                <label class="font-bold">Title:</label>
                <v-select class="mt-2" :options="titleOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="titleFilter"/>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <div class="w-full mb-5">
                <label class="font-bold mb-2">Full Name:</label>
                <vs-input
                  class="w-full mt-2"
                  name="fullName"
                  v-model="enquiryData.fullName"
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="Full Name"/>
                <span class="text-danger text-sm">{{ errors.first('fullName') }}</span>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <div class="w-full mb-5">
                <label class="font-bold mb-2">Mobile Number:</label>
                <vs-input
                  class="w-full mt-2"
                  name="mobileNumber"
                  v-model="enquiryData.phoneNumber"
                  v-validate="'required|numeric'"
                  data-vv-as="Mobile Number"/>
                <span class="text-danger text-sm">{{ errors.first('mobileNumber') }}</span>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <div class="w-full mb-5">
                <label class="font-bold mb-2">Enquiry type:</label>
                <v-select class="mt-2" :options="enquiryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="enquiryFilter"/>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <div class="w-full mb-5">
                <label class="font-bold mb-2">Message</label>
                <vs-textarea
                  class="w-full mt-2"
                  name="message"
                  v-model="enquiryData.message"
                  v-validate="'required'"
                  data-vv-as="Message"/>
                <span class="text-danger text-sm">{{ errors.first('message') }}</span>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <div class="w-full mb-16">
                <vs-button class="w-full" :disabled="!isFormValid" @click="submitHandler">Submit</vs-button>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="left" class="w-1/2 mobile-left" vs-xs="12">
          <div style="padding-top: 40px;" v-if="this.superAdminDetails">
            <p class="font-bold support" >{{ this.superAdminDetails.address.displayAddress }}</p><br />
            <p class="font-bold support">{{ this.superAdminDetails.address.street }}</p><br />
            <p class="font-bold support">{{ this.superAdminDetails.address.suburb }}, {{ this.superAdminDetails.address.postCode}}</p>
            <br />
            <br />
            <p class="font-bold support">Ph: {{ this.superAdminDetails.mobileNumber }}</p>
            <p class="font-bold support">Email: {{ this.superAdminDetails.email }}</p>
          </div>

        </vs-col>
      </vs-row>
    </vx-card>
    <!--tutorial card-->
    <vx-card class="mb-base" v-if="tutorialCard === true">
      <h2 class="mb-5">Tutorials</h2>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="custom-row">
        <vs-col class="custom-col w-full">
          <vs-input
            icon-after="true"
            label-placeholder="Search"
            icon="icon icon-search"
            icon-pack="feather"
            v-model="dataTableParams.search"
            @keyup="handleSearch"
            class="search-input w-full"
          />
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="custom-row">
        <vs-col
          vs-xs="12"
          vs-sm="6"
          vs-lg="4"
          class="custom-col"
          v-for="(tutorial, index) in tutorials"
          :key="index"
        >
          <div class="tutorial-wrap" @click="playVideo(tutorial)">
            <div class="video-image">
              <img v-if="tutorials[index].image" :src="tutorials[index].image" alt />
              <img
                v-else
                :src="$defaultVideoImage"
                alt
              />
            </div>
            <h3 class="h5 mb-1">
              <a href="#">{{ tutorials[index].name }}</a>
            </h3>
            <span>{{ tutorials[index].length }} min</span>
          </div>
        </vs-col>
      </vs-row>

      <div class="m-2">
        <div class="pagination-div float-none py-8" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>

      <vs-popup
        class="holamundo popup-example popup-large"
        title="Self Assessment"
        :active.sync="popupActive"
      >
        <div class="pt-5">
          <iframe
            width="100%"
            height="700px"
            allow="autoplay; fullscreen"
            frameborder="0"
            :src="videoSrc"
          ></iframe>
        </div>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import vSelect from "vue-select";

  export default {
    data() {
      return {
        contactCard: true,
        tutorialCard: false,
        centerId: '',
        enquiryData: {
          learningCenterId: '',
          title: '',
          fullName: '',
          phoneNumber: '',
          email: '',
          enquiryType: '',
          message: ''
        },
        superAdminDetails: '',
        titleOptions: [
          { label: 'Mr.', value: 'Mr.' },
          { label: 'Mrs.', value: 'Mrs.' },
          { label: 'Miss', value: 'Miss' }
        ],
        titleFilter: { label: 'Select title', value: ''},
        enquiryOptions: [
          { label: 'Feedback', value: 'Feedback' },
          { label: 'Training Support', value: 'Training Support' },
          { label: 'Error / Issue Handling', value: 'Error / Issue Handling' },
          { label: 'New Functionality', value: 'New Functionality' },
        ],
        enquiryFilter: { label: 'Select enquiry type', value: ''},

        /*tutorials*/
        videoSrc: "",
        popupActive: false,
        totalDocs: 0,
        dataTableParams: {
          search: "",
          dir: "asc",
          limit: 6,
          page: 1,
        },
        tutorials: [],
        serverResponded: false,
      }
    },
    components: {
      vSelect,
    },
    computed: {
      isFormValid() {
        return !this.errors.any()
          && this.enquiryData.fullName
          && this.enquiryData.phoneNumber
          && this.enquiryData.message
          && this.titleFilter.value != ''
          && this.enquiryFilter.value != ''
      },
      totalPage: function () {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
    },
    methods: {
      ...mapActions('supportEnquiry', [
        'saveSupportContact',
        'superAdminDetailsForSupport'
      ]),
      ...mapActions("tutorial", ["fetchAllTutorials"]),

      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: color,
        });
      },
      activeContact() {
        this.tutorialCard = false;
        this.contactCard = true;
      },
      activeTutorial() {
        this.contactCard = false;
        this.tutorialCard = true;
      },
      async submitHandler(){
        await this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            this.enquiryData.learningCenterId = this.centerId;
            this.enquiryData.email = this.$store.state.AppActiveUser.email;
            this.enquiryData.title = this.titleFilter.value;
            this.enquiryData.enquiryType = this.enquiryFilter.value;

            this.saveSupportContact(this.enquiryData).then(res => {
              this.showMessage(
                "Success",
                "‘Your message has been sent to our Admin",
                "success");
              this.$vs.loading.close();
              this.enquiryData = {
                learningCenterId: '',
                title: '',
                fullName: '',
                phoneNumber: '',
                email: '',
                enquiryType: '',
                message: ''
              };
              this.titleFilter = {label: 'Select title', value: ''};
              this.enquiryFilter = {label: 'Select enquiry type', value: ''};
              this.$validator.reset();
            })
          }
        })
      },

      /* tutorials */
      playVideo(tutorial) {
        this.videoSrc = tutorial.videoLink + "?autoplay=1";
        this.popupActive = true;
      },
      closePopup() {
        this.videoSrc = null;
        this.popupActive = false;
      },
      handleSearch() {
        this.dataTableParams.page = 1;
        //this.dataTableParams.search = this.search;
        this.getTutorials();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getTutorials();
      },

      getTutorials() {
        this.fetchAllTutorials(this.dataTableParams).then((res) => {
          this.tutorials = res.data.data.docs;

          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
        });
      },
    },
    watch: {
      "dataTableParams.page": function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getTutorials();
        }
      },
    },
    created() {
      this.centerId = this.$store.state.AppActiveUser.learningCenter._id;
      this.superAdminDetailsForSupport().then( async (res) => {
        this.superAdminDetails = await res.data.data;
      });
      this.getTutorials();
      this.contactCard = true;
    }
  }

</script>

<style lang="scss">
  .vs-dropdown--menu {
    ul {
      li {
        position: relative;
        z-index: 11;
      }
    }
  }

  .search-input {
    margin-bottom: 40px;
  }
  .custom-row {
    margin: 0;
    .custom-col {
      padding: 0 20px;
    }
    @media (min-width: 1480px) {
      margin: 0 -20px;
      .custom-col {
        padding: 0 20px;
      }
    }
    @media (min-width: 1480px) {
      margin: 0 -30px;
      .custom-col {
        padding: 0 30px;
      }
    }
    @media (min-width: 1640px) {
      margin: 0 -50px;
      .custom-col {
        padding: 0 50px;
      }
    }
  }

  .tutorial-wrap {
    margin-bottom: 40px;
    .h5 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin: 7px 0 10px;
      a {
        color: #2c2c2c;
      }
    }
  }

  .video-image {
    width: 100%;
    height: 230px;
    position: relative;
    cursor: pointer;
    @media (min-width: 1200px) {
      height: 280px;
    }
    @media (min-width: 1480px) {
      height: 320px;
    }
    &:before {
      content: "\f04b";
      background: rgba(96, 96, 96, 0.54);
      color: #ffffff;
      font-family: "FontAwesome";
      font-size: 50px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .vs-popup {
    background: #000000 !important;
    height: 700px;
    .vs-popup--content {
      max-height: none;
      height: 100%;
      .pt-5 {
        height: 100%;
      }
    }
    iframe {
      height: 100% !important;
    }
    .vs-popup--close {
      color: #ffffff !important;
    }
  }

  .support {
    color: black
  }
</style>
